<template>
  <div>
    <PageTitle title="Таблица Журнала" />

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>

      <template v-slot:content>
        <p class="mb-3">Здесь отобразится журнал.</p>

        <v-label>Выставьте</v-label>
        <ol class="pl-5">
          <li>Год</li>
          <li>Предмет</li>
          <li>Класс</li>
          <li>Период</li>
        </ol>
      </template>
    </TogglerOfWrapper>

    <div v-if="isAdmin" class="d-flex justify-center my-3">
      <router-link
        :to="{ name: 'teacher-journal-create' }"
        class="accent-button"
        >Создать Журнал</router-link
      >
    </div>

    <JSearch class="my-3" />
    <div v-if="journal">
      <div class="d-flex justify-center mt-5">
        <router-link
          :to="{
            name: 'sheets',
            query: {
              filterByJournalId: $route.query.filterByJournalId,
            },
          }"
          class="bordered-item ma-1"
          >К оценочным</router-link
        >
        <router-link
          :to="{
            name: 'dictations',
            query: {
              filterByJournalId: $route.query.filterByJournalId,
            },
          }"
          class="bordered-item ma-1"
          >К диктантам</router-link
        >
      </div>

      <JTable class="mt-5" />
      <JPreview class="my-2" />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { useAuthStore } from "@/store/auth";

import PageTitle from "@/elements/PageTitle.vue";
import JSearch from "./JSearch.vue";
import JTable from "./JTable.vue";

import JPreview from "./JPreview.vue";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";

const { isAdmin } = storeToRefs(useAuthStore());
const { journal } = storeToRefs(useSubjectsJournalStore());
</script>
