<template>
  <div>
    <div v-if="isPage">
      <PageTitle :title="title" />
      <div class="page-wrapper mb-3">

        <div class="g-col g-col--3-1fr">
          <FilterByChild v-if="isParent" />
          <FilterBySubject />
          <v-select
            class="only-desktop"
            :items="metricViewData"
            v-model="selectedMetricView"
            placeholder="Вид графика"
            variant="outlined"
            hide-details
            density="compact"
          />
        </div>
      </div>
    </div>

    <StatusWrapper :loadingStatus="loading" :isDataExisted="metrics.length > 0">
      <div :class="{ 'page-wrapper': isPage }">
        <div>
          <OnlyOnDesktop>
            <div class="mt-3">
              <div>
                <div v-if="chartData && chartValues" class="mb-10">
                  <Bar
                    v-if="selectedMetricView === 'bar'"
                    :data="chartData"
                    :options="chartValues"
                  ></Bar>

                  <Radar
                    v-else
                    :data="chartData"
                    :options="chartValues"
                  ></Radar>
                </div>
              </div>
            </div>
          </OnlyOnDesktop>

          <div>
            <!-- <Vue3EasyDataTable
              v-if="route.query.filterBySubjectId === '13'"
              :items="metrics"
              class="customize-table mt-5"
              :headers="[
                { text: 'Task', value: 'task' },
                { text: 'Level 1', value: 'level-1' },
                { text: 'Level 2', value: 'level-2' },
                { text: 'Level 3', value: 'level-3' },
                { text: 'Total Ball', value: 'total' },
              ]"
              hide-footer
              alternating
            >
              <template #item-task="{ valueDescription }">
                <p>{{ valueDescription }}</p>
              </template>
              <template #item-total="{ totalBall }">
                <p>{{ totalBall }}</p>
              </template>
              <template #item-level-1="{ valueBall }">
                <p>{{ valueBall }}</p>
              </template>
              <template #item-level-2="{ valueBallLevel2 }">
                <p>{{ valueBallLevel2 }}</p>
              </template>
              <template #item-level-3="{ valueBallLevel3 }">
                <p>{{ valueBallLevel3 }}</p>
              </template>
            </Vue3EasyDataTable> -->

            <Vue3EasyDataTable
              v-if="route.query.filterBySubjectId === '13'"
              :items="metrics"
              class="customize-table mt-5"
              :headers="[
                { text: 'Task', value: 'task' },
                { text: 'listening', value: 'listening' },
                { text: 'writing/reading', value: 'writing' },
                { text: 'speaking', value: 'speaking' },
                { text: 'Total Ball', value: 'total' },
              ]"
              hide-footer
              alternating
            >
              <template #item-task="{ valueDescription }">
                <p>{{ valueDescription }}</p>
              </template>
              <template #item-listening="{ listeningCompetenceBall }">
                <p>{{ listeningCompetenceBall }}</p>
              </template>
              <template #item-writing="{ writingReadingCompetenceBall }">
                <p>{{ writingReadingCompetenceBall }}</p>
              </template>
              <template #item-speaking="{ speakingCompetenceBall }">
                <p>{{ speakingCompetenceBall }}</p>
              </template>
              <template #item-total="{ totalBall }">
                <p>{{ totalBall }}</p>
              </template>
            </Vue3EasyDataTable>

            <div v-else>
              <div v-for="m in metrics" :key="m.id" class="mt-5">
                <h4 class="mb-2 text-center">
                  {{ m.valueDescription }} <br />
                  {{ getDateFormatWithTime(m.valueDate) }}
                </h4>

                <Vue3EasyDataTable
                  v-if="m.tableDataList"
                  :items="m.tableDataList"
                  class="customize-table mt-5"
                  :headers="headers"
                  hide-footer
                  alternating
                >
                  <template #item-task="{ exerciseName }">
                    <p>{{ exerciseName }}</p>
                  </template>
                  <template #item-competence="{ exerciseCompetences }">
                    <p v-for="c in exerciseCompetences" :key="c.id">
                      {{ c.competenceName }}
                    </p>
                  </template>
                  <template #item-result="{ exerciseTeacherBall }">
                    <p>{{ exerciseTeacherBall }}</p>
                  </template>
                </Vue3EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { Bar, Radar } from "vue-chartjs";
import Vue3EasyDataTable from "vue3-easy-data-table";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(ChartDataLabels);
ChartJS.register(Title);
ChartJS.register(Tooltip);
ChartJS.register(BarElement);
ChartJS.register(Legend);
ChartJS.register(CategoryScale);
ChartJS.register(LinearScale);
ChartJS.register(RadialLinearScale);
ChartJS.register(PointElement);
ChartJS.register(LineElement);
ChartJS.register(Filler);

import PageTitle from "@/elements/PageTitle.vue";

import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";

import { computed, onMounted, onUnmounted, ref, watch, defineProps } from "vue";
import { useRoute } from "vue-router";

import { useDatesChanger } from "@/composables/datesChanger";
import { useAuthStore } from "@/store/auth";
import { pushFilterToURL } from "@/service/router";

import OnlyOnDesktop from "@/components/OnlyOnDesktop.vue";
import FilterByChild from "@/components/filtersElements/FilterByChild.vue";
import FilterBySubject from "@/components/filtersElements/FilterBySubject.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";

const title = "Уровни освоения предметов в динамике";
const props = defineProps({ childId: { type: String, required: false } });

const { isParent, userId, isChild } = useAuthStore();
const { getDateFormatWithTime, getDateFormatWithoutTime } = useDatesChanger();
const { showError, showWarningPopup } = usePopupStore();
const route = useRoute();

const metrics = ref([]);
const chartData = ref(null);
const chartValues = ref(null);

const selectedMetricView = ref("bar");
const loading = ref("nothing");
const windowWidth = ref(window.innerWidth);

const isPage = computed(() => route.path.includes("metrics"));

const id = computed(() => {
  return props.childId ? props.childId : route.query.filterByChildId;
});

const headers = computed(() => {
  const titles = [
    { text: "Задание", value: "task" },
    { text: "Оценка учителя", value: "result" },
  ];

  if (windowWidth.value > 767) {
    titles.push({ text: "Навыки", value: "competence" });
  }

  return titles;
});

onMounted(() => {
  window.addEventListener("scale", setWindowWidth);
});

onUnmounted(() => {
  window.removeEventListener("scale", setWindowWidth);
});

function setWindowWidth(e) {
  windowWidth.value = e.target.innerWidth;
}

const metricViewData = computed(() => {
  const data = [{ title: "Столбики", value: "bar" }];

  if (windowWidth.value > 768) {
    return [...data, { title: "Паутинка", value: "radar" }];
  }

  return data;
});

onMounted(async () => {
  if (isPage.value && !id.value && isChild) {
    pushFilterToURL({ name: "filterByChildId", value: userId });
  }

  getChildMetrics();
});

const chartConfigs = computed(() => ({
  type: selectedMetricView.value,
  responsive: true,
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        pointStyle: "circle",
        usePointStyle: true,
        boxHeight: 8,
        boxWidth: 8,
      },
    },
    datalabels: {
      color: "black",
      labels: {
        title: {
          color: "black",
          anchor: "end",
        },
      },
    },
  },
}));

watch(() => id.value, getChildMetrics);
watch(() => route.query.filterBySubjectId, getChildMetrics);

async function getChildMetrics() {
  if (!route.query.filterBySubjectId || !id.value) {
    return;
  }
  loading.value = "loading";

  try {
    const r = await getChildMetricsHandler();

    if (r.status === 200) {
      metrics.value = r.data;

      if (route.query.filterBySubjectId === "13") {
        setForeignChartMetrics();
      } else {
        setChartMetrics();
      }
    }

    if (r.status === 207) {
      showWarningPopup(r.data.message);
    }
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getChildMetricsHandler() {
  return customAxios.post("/child/metric/by_subject", {
    childId: Number(id.value),
    subjectId: Number(route.query.filterBySubjectId),
  });
}

function setChartMetrics() {
  if (!metrics.value.length) {
    return;
  }
  let labelSet = [];
  let level1Set = [];
  let level2Set = [];
  let level3Set = [];

  metrics.value.forEach((element) => {
    if (element.valueDate) {
      labelSet.push(
        element.valueDescription +
          " (" +
          getDateFormatWithoutTime(element.valueDate) +
          ")"
      );
    }

    element.valueBall
      ? level1Set.push(element.valueBall.toFixed(2))
      : level1Set.push(0);

    element.valueBallLevel2
      ? level2Set.push(element.valueBallLevel2.toFixed(2))
      : level2Set.push(0);

    element.valueBallLevel3
      ? level3Set.push(element.valueBallLevel3.toFixed(2))
      : level3Set.push(0);
  });

  chartData.value = {
    labels: labelSet,

    datasets: [
      {
        label: "Динамика по предмету (уровень - 1, % )",
        fill: true,
        backgroundColor: "rgba(23, 189, 203, 0.8)",
        data: level1Set,
      },
      {
        label: "Динамика по предмету (уровень - 2, % )",
        fill: true,
        backgroundColor: "rgba(233, 101, 184, 0.8)",
        data: level2Set,
      },
      {
        label: "Динамика по предмету (уровень - 3, % )",
        backgroundColor: "rgba(253, 162, 83, 0.8)",
        data: level3Set,
      },
    ],
  };

  setMetricsConfigs();
}

function setForeignChartMetrics() {
  const labelSet = [];
  const listeningSet = [];
  const writingReadingSet = [];
  const speakingSet = [];
  const totalSet = [];

  metrics.value.forEach((element) => {
    if (element.valueDate) {
      labelSet.push(
        element.valueDescription +
          "(" +
          getDateFormatWithoutTime(element.valueDate) +
          ")"
      );
    }

    element.listeningCompetenceBall
      ? listeningSet.push(element.listeningCompetenceBall)
      : listeningSet.push(0);

    element.writingReadingCompetenceBall
      ? writingReadingSet.push(element.writingReadingCompetenceBall)
      : writingReadingSet.push(0);

    element.speakingCompetenceBall
      ? speakingSet.push(element.speakingCompetenceBall)
      : speakingSet.push(0);

    element.totalBall ? totalSet.push(element.totalBall) : totalSet.push(0);
  });

  chartData.value = {
    labels: labelSet,

    datasets: [
      {
        label: "Listening, %",
        backgroundColor: "rgba(23, 189, 203, 0.8)",
        data: listeningSet,
      },
      {
        label: "Writing / Reading, %",
        backgroundColor: "rgba(255, 194, 4, 0.8)",
        data: writingReadingSet,
      },
      {
        label: "Speaking, %",
        backgroundColor: "rgba(233, 101, 184, 0.8)",
        data: speakingSet,
      },
      {
        label: "Total, %",
        backgroundColor: "rgba(253, 162, 83, 0.8)",
        data: totalSet,
      },
    ],
  };

  setMetricsConfigs();
}

function setMetricsConfigs() {
  if (selectedMetricView.value === "bar") {
    chartValues.value = {
      ...chartConfigs.value,
    };
    return;
  }

  if (selectedMetricView.value === "radar") {
    chartValues.value = chartConfigs.value;
    return;
  }
}
</script>
